.arrow_img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  padding-block: 4px;
  cursor: pointer;
}

.arrow_img_div {
  width: 80px ;
  justify-content: space-between;
  display: flex;
}

.heading_main_div {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* height: 100px; */
  display: flex;
  gap: 5px;
}

.heading_text {
  color: #5b5050;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 64px; */
  margin-bottom: 35px;
  text-align: center;
  flex: 1;
}

@media (max-width: 600px) {
  .heading_text {
    font-size: 18px;
    margin-bottom: 10px;
    /* width: 50vw; */
  }
  .arrow_img_div_1{
    width: 0;
  }
}
