.reviews_main {
  background-color: #f2f2f2;
  height: auto;
  margin-top: 70px;
  padding-top: 30px;
  width: 100%;
}
.reviews_main_div {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
}

.reviews_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  text-align: center;
}
.reviews_swiper {
  margin-inline: 4%;
  position: relative;
  padding-block: 55px;
  display: flex;
  align-items: center;
}

.reviews_swiper_content {
  display: flex;
  gap: 20px;
}

.reviews_contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 6%;
}
.reviews_swiper_content_img {
  height: 125px;
  width: 125px;
  object-fit: contain;
}

.reviews_content_company {
  color: #5b5050;
  font-family: Raleway;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
.reviews_content_name {
  color: #5b5050;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 5px 0;
}
.reviews_content_desc {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.reviews_arrow_div {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
}

.reviews_arrow_circle {
  height: 40px;
  width: 40px;
  background-color: #6aadd7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .reviews_swiper_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .reviews_heading {
    font-size: 18px;
  }
  .reviews_main {
    margin-top: 0px;
    padding-top: 0px;
  }
  .reviews_swiper {
    padding-block: 2%;
  }
  .reviews_content_company {
    color: #5b5050;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  .reviews_content_name {
    color: #5b5050;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 5px 0;
  }
  .reviews_content_desc {
    color: #5b5050;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .reviews_swiper_content_img {
    height: 95px;
    width: 95px;
    object-fit: contain;
  }
}
