.main_contact_nav_div {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  /* position: fixed; */
  background-color: white;
  z-index: 2;
  align-items: center;
  /* padding-right: 10%; */
  height: 30px;
}

.main_contact_nav_buttons {
  display: flex;
  gap: 20px;
  margin-right: 5vw;
}

button:hover {
  filter: contrast(120%);
}

a:hover {
  filter: contrast(120%);
}

.main_contact_nav_first {
  width: 100px;
  height: 21px;
  /* flex-direction: column; */
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: 20px;

  border: none;
  border-radius: 4px;
  /* background: linear-gradient(90deg, rgba(141,172,174,1) 5%, rgba(214,92,76,1) 10%, rgba(232,150,73,1) 90%, rgba(141,172,174,1) 95%); */
  /* background: linear-gradient(90deg, rgba(112,168,206,1) 10%, rgba(215,94,76,1) 25%, rgba(236,160,72,1) 75%, rgba(112,168,206,1) 90%); */
  /* background: linear-gradient(90deg, rgba(106,173,215,1) 10%, rgba(214,91,76,1) 25%, rgba(238,168,72,1) 75%, rgba(106,173,215,1) 90%); */
  background: linear-gradient(90deg, rgba(106,173,215,1) 0%, rgba(214,91,76,1) 50%, rgba(238,168,72,1) 100%);
  cursor: pointer;
}

.main_contact_nav_second {
  display: flex;
  width: fit-content;
  height: 21.281px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #5b5050;
  font-family: Poppins;
  font-size: 14px;
  border: none;
  background: white;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

p {
  min-height: 2px;
}

h1 {
  min-height: 12px;
}

button,
a {
  cursor: pointer;
}

.book_a_tour_button {
  z-index: 11;
  position: fixed !important;
  right: -35px;
  top: 40%;
  border-radius: 6px;
  /* z-index: 2; */
  transform: rotate(90deg);
  /* background: #6aadd7; */
  background: linear-gradient(90deg, rgba(106,173,215,1) 0%, rgba(214,91,76,1) 50%, rgba(238,168,72,1) 100%);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

* {
  outline: none !important;
  /* max-width: 100%; */
}

label {
  /* color: black !important; */
}

textarea::placeholder {
  /* color: black; */
}

.MuiFormControl-root > div > div:hover {
  border-color: orange !important;
  outline: auto;
  outline-color: orange !important;
}

@media screen and (min-width: 1800px) {
  img {
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .smarter_big {
  }
  .smarter_small {
  }

  .smarter_middle {
  }
  .main_contact_nav_first {
    font-size: 12px;
    min-width: 110px;
  }
}

img {
}

.amenities_element_inner_div > img {
  width: auto !important;
  height: auto !important;
}
