.auth_main_component {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.auth_headings {
  display: flex;
}

.auth_headings > p {
  width: 200px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(172, 171, 171);
  padding-bottom: 10px;
  cursor: pointer;
  color: rgb(112, 110, 110);
}

.auth_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.auth_form > * {
  width: calc(100% - 20px);
  height: 50px;
}

.auth_email {
  padding-left: 20px;
  outline: 1px solid black;
}

.auth_password {
  height: 45px;
  border: none;
  outline: none;
  width: 90%;
  padding: 0 20px;
}

.forgot_password {
  color: #6aadd7;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  width: fit-content;
  cursor: pointer;
}

.forgot_password:hover {
  text-decoration: underline;
}

.submit_auth {
  width: 50%;
  max-width: 100%;
  background-color: #6aadd7;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border: none;
  border-radius: 12px;
}

.signup_text,
.forgot_password {
  max-width: 100%;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  width: fit-content;
  cursor: pointer;
}
