.main_navbar_div {
  height: 53px;
  overflow-x: visible;
  /* or auto, scroll, etc. depending on your needs */
  /* overflow-y: hidden; */
  width: 100vw;
  /* top: 30px; */
  /* background-image: url("../../../assets/nav_background_1800x600.png"); */
  /* background: linear-gradient(90deg, rgba(141,172,174,1) 10%, rgba(214,92,76,1) 25%, rgba(232,150,73,1) 75%, rgba(141,172,174,1) 90%); */
  /* background: linear-gradient(90deg, rgba(112,168,206,1) 10%, rgba(215,94,76,1) 25%, rgba(215,94,76,1) 75%, rgba(112,168,206,1) 90%); */
  /* background: linear-gradient(90deg, rgba(112,168,206,1) 10%, rgba(215,94,76,1) 25%, rgba(236,160,72,1) 75%, rgba(112,168,206,1) 90%); */
  /* background: linear-gradient(90deg, rgba(106,173,215,1) 10%, rgba(214,91,76,1) 25%, rgba(238,168,72,1) 75%, rgba(106,173,215,1) 90%); */
  background: linear-gradient(90deg, rgba(106,173,215,1) 0%, rgba(214,91,76,1) 50%, rgba(238,168,72,1) 100%);

  /* background-repeat: no-repeat; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  /* background-position: center; */
  display: flex;
  align-items: center;
  z-index: 2;
  /* position: fixed; */
}

@media screen and (min-width: 1800px) {
  .main_navbar_div {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

p {
  line-height: normal !important;
}

h1 {
  line-height: normal !important;
}

.logo_link:hover {
  filter: none;
}

.nav_item_list > li > a:hover {
  /* text-decoration: underline; */
}

.nav_item_list > li > a {
  text-decoration: none;
}

.logo {
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 6px;
  top: 6px;
  flex-shrink: 0;
}

.nav_item_list {
  list-style: none;
  display: flex;
  padding-left: 25px;
  /* align-items: center; */
}

.sidebar_nav {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .nav_item_list {
    /* gap:80%; */
    width: 100%;
  }

  .nav_item_list > * {
    display: none !important;
  }

  .sidebar_nav {
    position: absolute;
    right: 40px;
  }

  .sidebar_nav > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .sidebar_nav,
  .logo_nav_bar {
    display: block !important;
  }

  .main_navbar_div {
    margin-right: auto;
  }
}

.nav_item_list > * {
  color: #2b2b2b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 116px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  /* 150% */
}
.navbar_option {
  color: #2b2b2b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 116px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}


@media screen and (max-width:500px) {
  .logo {
    width: 100px;
  } 
  .nav-option{
    color:#6aadd7 ;
  }
}