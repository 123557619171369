.location1_banner {
  width: 100vw;
  height: fit-content;
  position: relative;
}

.individual_maps {
  margin: 20px 2%;
}

.location1_banner > img {
  width: 100vw;
  height: 90vh;
}

.book_first_bottom {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.book_first_inner {
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
}

.book_first_title {
  color: #5b5050;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  /* 75% */
}

.book_first_desc {
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  /* 142.857% */
}

.book_first {
  width: 55% !important;
}

.book_second {
  width: 400px !important;
}

.book_tour_main {
  /* width: 96vw; */
  margin-inline: 4%;
  margin-top: 4%;
  margin-bottom: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
  gap: 20px;
}

@media screen and (max-width: 1060px) {
  .book_tour_main {
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    gap: 40px !important;
  }
  .book_tour_main > * {
    width: 80% !important;
  }
}

.book_first_icon {
  width: 20px;
  height: 16px;
  flex-shrink: 0;
  fill: #fff;
  background: #6aadd7;
}

.book_first_text {
  color: #5b5050;
  text-align: right;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 160% */
}

.book_first_bottom_text {
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.book_second_button {
  background: #6aadd7;
  width: 100%;
  max-width: 96vw;
  height: 40px;
  flex-shrink: 0;
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 150% */
}

.book_tour_image {
  width: 14.737px;
  height: 14.737px;
  margin-top: 5px;
  flex-shrink: 0;
  background: #6aadd7;
}

.book_tour_text {
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  flex: 1;
  /* 150% */
}

.book_tour_grid {
  display: grid;
  /* place-items: center; */
  margin: 15px 0;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.book_tour_grid_inner {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-top: 10px;
}

.offering_title {
  margin-left: 10px;
  color: #5b5050;
  font-family: Raleway;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 75% */
}

.offering_main_div {
  margin: 0 2.5%;
}

.offering_card_main_div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.offering_card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offering_card_title {
  color: #5b5050;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 0 0;
  /* 120% */
}

.offering_card_desc {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* min-height: 45px; */
  /* margin: 5px 0 0 0; */
  /* 171.429% */
}

.offering_button {
  cursor: pointer;
  color: #fff;
  font-family: Raleway;
  padding: 2px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  /* 171.429% */
  border-radius: 5px;
  background: #6aadd7;
  border: none;
  /* width: 108px; */
  display: flex;
  justify-content: center;
}

.offering_card_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.offering_cards {
  display: flex;
  overflow: scroll;
  flex-direction: row;
  gap: 20px;
}
.offering_cards > * {
  width: 33% !important;
  min-width: 250px;
}

.offering_cards::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1200px) {
  .offering_cards {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .offering_card_main_div {
    min-width: 21.8vw !important;
  }
}

.offering_card_main_div > img {
  width: 100% !important;
}

.amenities_main_div {
  margin: 40px 0%;
}

.Amenities_cards {
  display: grid;
  margin: 10px 2%;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.ameneties_card_main_div {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  height: 70px;
}

.amenities_card_img_div {
  background-color: #d9d9d9;
  width: 39px;
  height: 39px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
}

.ameneties_card_img {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  object-fit: contain;
}

.ameneties_card_desc {
  color: #000;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  /* 457.143% */
}

.amenities_photos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin: 0 2%;
}

.amenities_photo_image {
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .offering_cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .Amenities_cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .amenities_photos {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width:500px) {
  .offering_cards{
    flex-direction: column;
  }
  .offering_card_main_divvv{
    width: 100% !important;

  }
  .offering_card_main_div{
    width: 100% !important;
    
  }
}
