.refer_main_component {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 2;
  bottom: 0;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(50%);
}

.refer_inner_component {
  background-color: white;
  width: 50%;
  height: 90%;
  overflow: scroll;
  border-radius: 15px;
  border: none;
}

.refer_inner_component::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 800px) {
  .refer_inner_component {
    width: 90%;
  }
}
