.career_banner {
  background-image: url("../../../assets/careers/shutterstock_2354325731.jpg");
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
}

.orange-border .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #6aadd7 !important;
}

.person_title {
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #5b5050;
}

.MuiTouchRipple-root {
  display: none;
}

.css-8je8zh-MuiTouchRipple-root {
  display: none;
}

.person_desc {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: #5b5050;
}

.gt_textt {
  font-size: 10px;
  font-weight: 600;
  color: #897c7c;
}

.gt_textt > span {
  color: blue;
}

.career_banner_heading {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  /* 57.5% */
}

.career_banner_text {
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;

  /* 143.75% */
}

.career_banner_button {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  width: 166px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #6aadd7;
  border: none;
  cursor: pointer;
  height: 40px;
  margin-top: 30px;
}

.career_anchor {
  color: #fff;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  /* 191.667% */
  text-decoration-line: underline;
}

.career_banner_inner_div {
  margin-left: 5%;
  min-width: 150px;
}

.why_career_main {
  margin: 20px 5%;
}

.why_career_header {
  display: flex;
  align-items: center;
}

.header_tittle {
  color: #5b5050;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.forw_back {
  display: flex;
  gap: 30px;
  margin-left: auto;
  padding-right: 30px;
}

.forw_back > img {
  width: 11.615px;
  height: 15.092px;
  flex-shrink: 0;
}

.why_career_inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.wcic_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  /* 125% */
}

.wcic_desc {
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 192.857% */
}

.why_career_inner_image > img {
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.cv_heading {
  /* color: #5b5050; */
  /* font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; */
  text-align: center;
  /* padding-top: 20px; */
  margin-top: 70px;
  padding-bottom: 30px;
}

.slack {
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 50%;
  background: #fafafa;
}

.slack_text {
  color: #5b5050;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  /* 170.833% */
}

.cv_header_text {
  color: #5b5050;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  width: 573px;
}
.cv_header_text_lineHeight {
  line-height: 25px !important;
}
.cv_header {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.cv_header_scroll {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
  align-items: center;
}

.cv_hr_line {
  width: 27%;
  /* width: 407px; */
  height: 4px;
  flex-shrink: 0;
  background: #d9d9d9;
}

.color_arrow {
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
  background-color: #6aadd7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv_team {
  background: rgba(217, 217, 217, 0.2);
  height: fit-content;
}

.leaders_heading {
  color: #5b5050;
  text-align: justify;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 32px; */
  text-align: center;
  margin-left: auto;
}

.leaders_header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96vw;
}

.persons {
  margin: 0 5%;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.persons_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.persons_image > img {
  width: 100%;
}

.persons_button {
  max-width: 207px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(217, 217, 217, 0.2);
  border: none;
  margin-bottom: 10px;
  color: #5b5050;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
}

@media screen and (max-width: 900px) {
  .persons {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .why_career_inner {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .wcic_heading {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .why_career_inner_image {
    margin-top: 10px;
  }
  .why_career_inner_image > img {
    width: 100%;
    height: 200px;
  }
  .header_tittle {
    font-size: 20px;
  }
  .cv_heading {
    margin-top: 0px;
    padding-bottom: 10px;
    margin: 0;
    padding-inline: 15px;
  }
  .cv_header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .slack {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 50%;
    background: #fafafa;
  }
  .cv_header_text {
    padding-inline: 20px;
    width: auto;
  }
  .persons {
    margin: 0 5%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
@media screen and (min-width: 1500px) {
  .slack {
    height: 500px;
    width: 500px;
  }
}

.leaders_dialogue_main {
  height: fit-content;
  background-color: white;
  width: 50%;
  padding: 20px;
}

.leaders_logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.leaders_logo_2nd_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.leaders_logo_2nd_div > p {
  margin: 0;
}

.leaders_logo_title {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
}

.leaders_logo_desig {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
}

.leaders_logo_desc {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  color: rgb(38, 38, 38);
}

@media screen and (max-width: 800px) {
  .career_banner {
    height: 60vh;
  }
  .career_banner_heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  .career_banner {
    height: 30vh;
  }
  .career_banner_heading {
    font-size: 20px;
  }
  .career_banner_text {
    font-size: 14px;
  }
  .career_banner_button {
    font-size: 14px;
    width: 100px !important;
    height: 30px;
    margin-top: 10px;
  }
}
