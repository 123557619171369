.w1_banner {
  height: 80vh;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  align-items: center;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
}

.members_reviewss {
  display: flex;
  gap: 15px;

  overflow: scroll;
  align-items: center;

  padding: 40px 5%;
}

.offering_card_contentt {
  flex: 1;
}

.offering_card_buttons {
  gap: 10px !important;
}

.questions_main_div {
  margin: 0 1.5%;
}

.offering_card_main_divvv {
  min-width: 300px;
}

.member_title > p {
  font-weight: 600 !important;
  padding-top: 5px;
}

.questions_title {
  font-weight: 600 !important;
}

.offering_card_contentt {
  display: flex;
  background-color: #f8f8f8;
  padding: 15px 15px 15px 15px;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  /* width: 100%; */
}

.offering_card_contentt > .offering_card_desc1 {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.offering_card_contentt > div > button {
  font-weight: 600;
}

.smarterr {
  display: grid;
  margin: 20px 3%;
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
  gap: 20px;
}

.smarter_content {
  min-width: 300px !important;
  max-width: 94vw;
  min-height: fit-content;
}

.amenities_element_inner_div {
  margin-bottom: 50px;
}

.smarter_contentt_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 40px;
  min-width: fit-content;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 48px;
}

.tree {
  max-width: 94vw;
  display: flex;
  justify-content: end;
  margin: 10px 10px 30px 5px;
}

@media screen and (min-width: 1200px) {
  .offerinnng_card_image {
    width: 100% !important;
  }
}

.smarter_contentt_desc {
  color: #5b5050;
  /* min-width: 300px; */
  text-align: justify;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px !important;
  /* 142.857% */
}

.smimage {
  height: 40vh;
  width: 94vw;
  margin: 3vw;
  margin-left: 0;
}

.w1_banner_heading {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin: 0;
}

.w1_banner_desc {
  color: #fff;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-top: 0;
}

.w1_banner_button {
  display: inline-flex;
  min-height: 39px;
  padding: 0px 19.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  background: #6aadd7;
  color: #fffafa;
  border: none;
  cursor: pointer;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  /* 0% */
}

@media screen and (min-width: 1500px) {
  .smarterr {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1100px) {
  .smarterr {
    height: fit-content !important;
  }
}
@media screen and (max-width: 600px) {
  .smarter_contentt_heading {
    font-size: 20px;
  }
  .w1_banner_heading {
    font-size: 20px;
  }
  .w1_banner_desc {
    font-size: 12px;
    line-height: 15px;
    margin-right: 2%;
  }
  .w1_banner_button {
    display: inline-flex;
    min-height: 22px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    background: #6aadd7;
    color: #fffafa;
    border: none;
    cursor: pointer;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
  }
  .w1_banner {
    height: 28vh;
  }
}
