@font-face {
  font-family: 'Raleway';
  src: url('./assets/01WorkWell/Font/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}



* {
  font-family: Raleway;
}

.leaders_card {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  /* background-color: #fff; */
  z-index: 10;
}
.leaders_card:hover {
  background-color: #6aadd777;
}

.leaders_card_img_div {
  height: 330px;
  width: 100%;
  display: flex;
  background-color: #f1f1f1;
  z-index: -10;
}

.leaders_card_heading {
  font-family: Raleway;
  /* display: flex; */
  font-size: 20px;
  color: #5b5050;
  font-weight: 600;
  text-align: center;
  margin-block: 20px;
  z-index: -10;
}
.leaders_card_heading1 {
  font-family: Raleway;
  /* display: flex;  */
  font-size: 16px;
  color: #5b5050;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  z-index: -10;
}
.leaders_swiper {
  width: 100%;
}

.meetingroom_review {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
}

.meetingroom_review_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 64px; */
  margin-block: 40px;
  text-align: center;
}
.meetingroom_review_swiper {
  margin-inline: 4%;
  /* background-color: red; */
  position: relative;
}

.meetingroom_review_swiper_content {
  display: flex;
  gap: 20px;
  /* margin-bottom: 30px; */
}
.meetingroom_review_swiper_content_img {
  height: 70px;
  width: 100px;
  display: flex;
}
.meetingroom_review_swiper_profile {
  display: flex;
  flex-direction: column;
}

.meetingroom_review_swiper_company {
  color: #5b5050;
  font-family: Raleway;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 100% */
  margin: 0;
}

.meetingroom_review_swiper_name {
  color: #5b5050;
  font-family: Raleway;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 5px 0;
}

.meetingroom_review_swiper_desc {
  color: #5b5050;
  /* text-align: justify; */
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.meetingroom_review_arrow_div {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  top: 50%;
  bottom: 50%;
}

.meetingroom_review_arrow_circle {
  height: 40px;
  width: 40px;
  background-color: #6aadd7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.Typewriter {
  box-shadow: none !important;
  text-shadow: none !important;
}

.type_writer {
  background-color: #6aadd7 !important;
  font-size: 100px;
  display: flex;
}

.typewriter-container .Typewriter__wrapper {
  box-shadow: none !important;
  /* background: #0000001f !important; */
  background: #ff00ff05 !important;
  text-shadow: none !important;
  border-radius: 0;
  padding-inline: 5px;
}

/* ===============swiper component============= */
.swiper_main {
  display: flex;
  flex-direction: column;
}

.swiper_heading_main_div {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* height: 100px; */
  display: flex;
  gap: 5px;
}

.swiper_heading_text {
  color: #5b5050;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 64px; */
  margin-bottom: 35px;
  text-align: center;
  flex: 1;
}

.swiper_arrow_img_div {
  width: 80px;
  justify-content: space-between;
  display: flex;
}

.swiper_card_img {
  height: 200px;
  width: 100%;
  display: flex;
}

.swiper_card_heading {
  font-family: Raleway;
  display: flex;
  font-size: 20px;
  color: #5b5050;
  font-weight: 600;
  line-height: 30px;
  border-left: 3px solid #6aadd7;
  padding-left: 8px;
  margin-top: 10px;
}
.swiper_card_heading1 {
  font-family: Raleway;
  display: flex;
  font-size: 14px;
  color: #5b5050;
  font-weight: 400;
  line-height: 24px;
  padding: 5px;
  margin-left: 6px;
}

.event_swiper_css {
  margin-inline: 10px;
}

.home_swiper_carausel {
  margin-inline: 0%;
}

.text-input-css {
  color: #000;
}
/* =====================================input-row-form==================================== */
.input-row-form {
  display: flex;
  gap: 15px;
}
.input-row-form-wraper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.image_form_main {
  height: auto;
  min-height: 600px;
  width: 100vw;
  display: flex;
  position: relative;
  justify-content: center;
}
.image_form_inner {
  height: auto;
  max-width: 60%;
  padding: 20px;
  width: 100%;
  display: flex;
  background-color: #fff;
  position: relative;
  margin: 5% 0;
  border-radius: 5px;
}

.image_form_image {
  height: 100%;
  width: 100vw;
  display: flex;
  position: absolute;
}

/* ============================  Grid Container   =============================== */
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Initially 4 columns */
  grid-template-rows: repeat(1, 1fr); /* Initially 2 rows */
  gap: 30px;
  margin-block: 2%;
}

.grid-item {
  /* background-color: #ddd; */
  padding: 20px;
  text-align: center;
}
.grid-item-title {
  font-size: 14px;
  margin-top: 10px;
  color: #5b5050;
}

@media screen and (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr); /* Adjusted for smaller screens */
    grid-template-rows: auto; /* Auto rows */
  }
}
@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Adjusted for smaller screens */
    grid-template-rows: auto; /* Auto rows */
  }
}

.nav-option {
  /* color: #2b2b2b; */
  color: #fff;
  cursor: pointer;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  cursor: pointer;
  /* padding: 10px; */
  letter-spacing: 0.5px;
}

.nav-option:hover {
  color: #6aadd7;
}

.navbar-options {
  margin-left: 9.1%;
  display: flex;
  gap: 22px;
  align-items: center;
}
.navbar-drawer {
  display: none;
}
@media screen and (max-width: 768px) {
  .navbar-options {
    display: none;
  }
  .navbar-drawer {
    display: flex;
    margin-right: 50px;
  }
}

.review-why-altis {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  margin-inline: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.review-why-altis_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  /* width: 25%; */
}
@media screen and (max-width: 1000px) {
  .review-why-altis {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
  .review-why-altis_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.custom-banner-main {
  height: 500px;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 0;
}

.custom-banner-main-img {
  height: 100%;
  width: 100vw;
  object-fit: fill;
  position: relative;
  align-items: center;
}

.explore_workspace {
  display: flex;
  background-color: #f8f8f8;
  padding: 12px 15px 15px 15px;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  flex: 1;
}

.explore_workspace_title {
  color: #5b5050;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.explore_workspace_desc {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 2px;
}

.home_banner_form {
  display: none;
}
.home_banner_form1 {
  display: flex;
}
.button_main {
  background-color: #6aadd7;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding-inline: 25px;
  padding-block: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yesno {
  color: #5b5050;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .home_banner_form {
    display: flex;
    margin-top: 20px;
  }
  .home_banner_form1 {
    display: none;
  }
}

@media (max-width: 600px) {
  .swiper_heading_text {
    font-size: 18px;
    /* width: 50vw; */
    margin-bottom: 15px;
  }
  .swiper_arrow_img_div {
    width: 60px;
  }
  .image_form_inner {
    height: auto;
    max-width: 80%;
  }
  .button_main {
    font-size: 14px;
  }
  .yesno {
    font-size: 14px;
  }
}

.new-bg{
  background: linear-gradient(90deg, rgba(106,173,215,1) 0%, rgba(214,91,76,1) 50%, rgba(238,168,72,1) 100%) !important;
}
