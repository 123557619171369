.event_banner_img {
  background-image: url("../../../assets/location1/images/Rectangle 73 (1).png");
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0 !important;
}

.banner_carous {
  width: 100%;
  position: relative;
  height: 100%;
  right: 15%;
  top: 10%;
}

.banner_carous > ul {
  height: 100%;
}

.event_banner_button {
  width: 150px;
}

.event_banner_img_inner {
  margin-left: 5%;
}

.banner_event_main_component {
  display: flex;
  padding: 20px 4%;
  gap: 80px;
  justify-content: center;
}

.banner_event_content {
  width: 48%;
  max-width: 90%;
}

.banner_event_carousel {
  min-width: 480px;
  height: auto;
  padding-bottom: 2%;
}

.carousel_first {
  background-color: #28d1b4;
  width: 250px;
  height: 250px;
}

@media screen and (max-width: 1100px) {
  .banner_event_main_component {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1100px) {
  .carrss > * {
    width: 24.1%;
    min-width: 24.1% !important;
  }
}

@media screen and (max-width: 820px) {
  .banner_event_main_component {
    flex-direction: column;
    align-items: center;
  }

  .banner_event_content {
    width: 100%;
  }

  .banner_event_carousel {
    width: 50%;
  }
}

.carousel_second {
  background-color: blue;
  width: 220px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 60%;
  padding-top: 10px;
  left: 37%;
}

.carousel_second > * {
  height: 100% !important;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.banner_event_content_text {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px !important;
  /* 285.714% */
}

.banner_event_content_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 200% */
}

.spaces_heading {
  color: #5b5050;
  text-align: center;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 75% */
}

.spaces_main_component {
  margin: 0 5%;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
}

.banner_carousel_spaces {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 10px;
}

.sub_title_spaces {
  color: #5b5050;
  text-align: left;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 10px 0;
  line-height: 24px;
}

.spaces_heading_footer {
  padding: 0 20px;
  border: 1px solid #d9d9d9;
  width: 98.4%;
}

.spaces_heading_footer_div {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}

.auditdata {
  display: flex;
  align-items: center;
  gap: 15px;
}

.auditdata_button {
  border-radius: 5px;
  background: #6aadd7;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.auditdata_img {
  width: 15px;
  height: 20px;
  flex-shrink: 0;
  fill: #5b5050;
}

.auditdata_text {
  color: #5b5050;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.range_heading {
  color: #5b5050;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.learn_more {
  color: #6aadd7 !important;
  font-family: Raleway !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  /* line-height: 64px !important; */
  margin-block: 0;
  margin-left: 12px;
  cursor: pointer;
  width: fit-content;
  /* 457.143% */
}

.cardd_titlee {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  line-height: 64px;
  /* 320% */
}

.cardd_descc {
  color: #5b5050;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  text-decoration: none;
}

.second_half_right {
  height: 100%;
}

.gt_main_component {
  background-image: url("../../../assets/event/Rectangle\ 248.png");
  height: 70vh;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.inner_gt_div {
  background: #fff;
  width: 60%;
  height: auto;
  height: fit-content;
  padding: 40px 20px;
  border-radius: 6px;
  margin-top: 60px;
}

.gt_heading {
  color: #5b5050;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  margin: 0;
  margin-bottom: 20px;
}

.gt_inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .gt_inputs {
    grid-template-columns: 1fr;
  }
}

.gt_button {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  display: inline-flex;
  padding: 7px 85px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #6aadd7;
  border: none;
  margin-top: 10px;
  /* 177.778% */
}

.banner_carouss {
  height: 80%;
  width: 90%;
}

.guestdiv:hover {
  border: 1px solid black;
}

.guestdiv {
  overflow: hidden;
  border-radius: 8px;
  z-index: 2;
}

.guestdiv > input {
  outline: none;
}

.plmidiv:hover {
  border: 1px solid black;
  border-left: none;
  border-radius: 0 8px 8px 0;
}

.plmidiv {
  border: 1px solid rgb(133, 133, 133);
}

@media screen and (max-width: 1100px) {
  .ameneties_elements {
    height: fit-content !important;
  }
}

@media screen and (max-width: 600px) {
  .banner_carousel_spaces {
    height: 30vh;
    width: 100%;
    border: 1px solid #fff;
    padding-inline: 0 !important;
  }
  .banner_event_main_component {
    gap: 20px;
  }
  .spaces_main_component {
    padding: 0 5%;
    width: calc(100% - 10%);
    margin: 0;
    border-radius: 0px 0px 5px 5px;
    background: #fff;
  }
  .spaces_heading_footer{
    padding: 0 5%;
    width: calc(100% - 10%);
  }
}

